import React from "react"
import Container from "@ecom/ui/components/Container"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

import * as styles from "./otherOffers.module.scss"

export type Tags = {
  name: string
  seos?: {
    shortTitle: string
    url: string
  }[]
  pages?: {
    shortTitle: string
    url: string
  }[]
}

export type OtherOffersProps = {
  tags?: Tags[]
  title?: string
  orderNum?: string
}

const OtherOffers = ({ tags, title, orderNum }: OtherOffersProps) => (
  <section className={styles.section} data-exclude={orderNum}>
    <Container>
      <Accordion classes={{ root: styles.dropdown }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          classes={{ root: styles.fixJumping }}
        >
          <h2 className={styles.title}>{title || "Другие предложения по кредитам"}</h2>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.content}>
            {tags?.map(({ name, seos, pages }) => (
              <div className={styles.itemTag} key={name}>
                <h4 className={styles.tagTitle}>{name}</h4>
                <div>
                  {seos &&
                    seos.map(
                      (item) =>
                        item?.shortTitle && (
                          <div className={styles.tagBlock} key={item?.url}>
                            <a
                              href={item?.url}
                              className={styles.tagShort}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {item?.shortTitle}
                            </a>
                          </div>
                        )
                    )}
                  {pages &&
                    pages.map(
                      (item) =>
                        item?.shortTitle && (
                          <div className={styles.tagBlock} key={item?.url}>
                            <a
                              href={item?.url}
                              className={styles.tagShort}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {item?.shortTitle}
                            </a>
                          </div>
                        )
                    )}
                </div>
              </div>
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
    </Container>
  </section>
)

export default OtherOffers
