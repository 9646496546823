import React from "react"
import { decode } from "html-entities"

import Container from "@ecom/ui/components/Container"

import * as styles from "./textBlock.module.scss"

type TextBlockProps = {
  html?: string
  orderNum?: string
}

export default function TextBlock({ html, orderNum }: TextBlockProps) {
  if (html) {
    const text = { __html: decode(html) }

    return (
      <div data-exclude={orderNum}>
        <Container className={styles.container}>
          <div dangerouslySetInnerHTML={text} id="seoText" />
        </Container>
      </div>
    )
  }
  return null
}
