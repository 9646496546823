import getSearchParam from "@ecom/ui/utils/getSearchParam"

export const getUTMParams = () =>
  (getSearchParam("utm_campaign") === "VK_ru_Jul23_halva_IntP_creativ_1_cross" &&
    getSearchParam("utm_medium") === "Social" &&
    getSearchParam("utm_source") === "Media" &&
    getSearchParam("utm_content") === "IntP_SD_SP_Cr_1" &&
    getSearchParam("utm_term") === "HAC03") ||
  (getSearchParam("utm_campaign") === "OK_ru_Jul23_halva_IntP_creativ_1_cross" &&
    getSearchParam("utm_medium") === "Social" &&
    getSearchParam("utm_source") === "Media" &&
    getSearchParam("utm_content") === "IntP_SD_SP_Cr_1" &&
    getSearchParam("utm_term") === "HAC04") ||
  (getSearchParam("utm_campaign") === "Mail_ru_Jul23_halva_BrSc_creativ_1_cross" &&
    getSearchParam("utm_medium") === "Display" &&
    getSearchParam("utm_source") === "Media" &&
    getSearchParam("utm_content") === "BrSc_SD_SP_Cr_1" &&
    getSearchParam("utm_term") === "HAC06")
