import React, { useEffect, useState } from "react"
import { useLocation } from "@gatsbyjs/reach-router"

import { HalvaFooter } from "@ecom/ui/components/HalvaFooter"
import { HeaderWithBurger } from "@ecom/ui/components/Header/HeaderWithBurger"

import Layout, { PageLayoutProps } from "../components/Layouts/mainPage"

import Banner, { BannerProps } from "../components/Seo/Banner"
import Advantages from "../components/Advantages"
import Discounts from "../components/Discounts"
import Conditions from "../components/Conditions"
import TextBlock from "../components/Seo/TextBlock"
import WaysToGetCard from "../components/WaysToGetCard"
import { FormPKW } from "../components/FormPKW"
import Partners from "../components/Partners"
import OtherOffers from "../components/OtherOffers"
import { Tags } from "../components/OtherOffers/OtherOffers"
import { BlackHalvaLogoV2 } from "../components/Headers/Logos"
import { OTHER_ITEMS } from "../components/Advantages/helpers"

import headerLinksV2 from "../data/dataHeader/headerLinksV2.json"
import footerLinksV2 from "../data/dataFooter/footerLinksV2.json"

import sbpTags from "../static/sbpTags.json"
import { createSeoData } from "./helpers/createSeoData"

// КОСТЫЛИ
import * as KOSTYL from "../helpers/KOSTYL.json"
import { handleClickHeader } from "../helpers/WatcherDL/utils/handleClickHeader"

let tagTitle: string
let tags: Tags[]

if (process.env.GATSBY_PATH_PREFIX === "/apply/sbp") {
  tagTitle = "Дополнительная информация по СБП"
  tags = sbpTags
}

type Props = {
  pageContext: {
    url: string
    title: PageLayoutProps["title"]
    shortTitle?: string
    description: PageLayoutProps["description"]
    upperBannerText: BannerProps["html"]
    remoteImage: BannerProps["large"]
    remoteImageMob: BannerProps["mob"]
    seoBlockText?: string
    shortSeoBlockText: string
    phones?: string[]
    noIndex: boolean
    hasOtherPercents: boolean
    ligal: string
    variables: {
      halvaOstatok: {
        value: string
      }
    }
  }
}
//
export default function Seo({ pageContext }: Props) {
  const {
    upperBannerText,
    url,
    title,
    shortTitle,
    description,
    seoBlockText,
    phones,
    ligal,
    noIndex,
    hasOtherPercents,
    remoteImage,
    remoteImageMob,
    variables: { halvaOstatok },
  } = pageContext

  if (!halvaOstatok) {
    throw new Error("The halvaOstatok variable is required!!!")
  }

  const location: Location = useLocation()
  const [items, setItems] = useState(OTHER_ITEMS)

  useEffect(() => {
    if (location.pathname.includes("clothes")) {
      setItems(KOSTYL["18months"])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const seoData = createSeoData(title, description, upperBannerText, url, shortTitle)

  // Получение первой страницы на которую зашел
  useEffect(() => {
    if (!sessionStorage.getItem("firstURL")) {
      sessionStorage.setItem("firstURL", document.URL)
    }
  }, [])

  const onClick = () => {
    window.open("https://halvacard.ru/", "_blank")
  }

  return (
    <Layout
      seoData={seoData}
      ligal={ligal}
      phones={phones}
      buttonText="Получить"
      noPhone
      noFooter
      noHeader
      noIndex={noIndex}
    >
      <HeaderWithBurger
        Logo={BlackHalvaLogoV2}
        onLogoClick={onClick}
        onSidebarBtnClick={() => handleClickHeader("click_cta", "burger_menu")}
        onHeaderBtnClick={() => handleClickHeader("click_cta", "header")}
        orderNum="99"
        listLinksForNav={headerLinksV2}
        anotherStylesTypeV2
      />
      <Banner
        withButton
        html={upperBannerText}
        large={remoteImage}
        mob={remoteImageMob}
        otherButtonText
        orderNum="1"
      />
      <Advantages items={hasOtherPercents ? items : undefined} orderNum="2" />
      <Discounts orderNum="3" />
      <Conditions rate="17" orderNum="4" />
      <TextBlock html={seoBlockText} orderNum="5" />
      <WaysToGetCard orderNum="6" />
      <FormPKW title="Оформите карту" dataLayerName="shortPersonalForm" orderNum="7" longForm />
      <Partners orderNum="8" />
      {tags && <OtherOffers title={tagTitle} tags={tags} orderNum="9" />}
      <HalvaFooter listLinksForFooter={footerLinksV2} />
    </Layout>
  )
}
