import React, { useEffect, useState } from "react"
import { decode } from "html-entities"

import { ImageDataLike } from "gatsby-plugin-image"

import Button from "@material-ui/core/Button"
import Container from "@ecom/ui/components/Container"
import { Img } from "./Img"
import { ImgWithUtm } from "./ImgWithUtm"
import { getUTMParams } from "./helpers/getUTMParams"
import * as styles from "./banner.module.scss"
import { handleClickBanner } from "../../../helpers/WatcherDL/utils/handleClickBanner"

export type BannerProps = {
  withButton?: boolean
  html: string
  large: ImageDataLike
  mob: ImageDataLike
  otherButtonText?: boolean
  orderNum?: string
}

export default function Banner({
  withButton = true,
  html,
  large,
  mob,
  otherButtonText,
  orderNum,
}: BannerProps) {
  const [bannerImgWithUTM, setBannerImgWithUTM] = useState(false)
  const text = { __html: decode(html) }

  // Поменять изображение на баннера при передаче UTM-меток
  // :TODO найти лучшее решение а не через дом менять и без белого экрана
  useEffect(() => {
    if (getUTMParams()) {
      setBannerImgWithUTM(true)
      document.body.style.display = "block"
    }
  }, [])

  return (
    <section className={styles.banner} data-exclude={orderNum}>
      {bannerImgWithUTM ? (
        <ImgWithUtm className={styles.imgOuter} alt="bg image" />
      ) : (
        <Img className={styles.imgOuter} alt="bg image" large={large} mob={mob} />
      )}

      <Container className={styles.container}>
        <div className={styles.textBlock} dangerouslySetInnerHTML={text} />
        {withButton && (
          <Button className={styles.btn} onClick={() => handleClickBanner("click_cta")}>
            {otherButtonText ? "Получить карту" : "Заполнить Заявку"}
          </Button>
        )}
      </Container>
    </section>
  )
}
